import { Container, Row, Col } from "react-bootstrap";
import ProfilIcon from "./assets/icons/icon_ic_person.svg";
import JobIcon from "./assets/icons/icon_ic_job.svg";
import MicIcon from "./assets/icons/icon_ic_mic.svg";
import SettingIcon from "./assets/icons/icon_ic_settings.svg";
import BankingIcon from "./assets/icons/icon_navbar_ic_banking.svg";
import React, { useState, useEffect } from "react";
import ExternalLink from "./components/ExternalLink";

export default function Info() {
  const [isHoverProfil, setisHoverProfil] = useState(false);
  const [isHoverExpirience, setisHoverExpirience] = useState(false);
  const [isHoverFormation, setisHoverFormation] = useState(false);
  const [isHoverCompetence, setisHoverCompetence] = useState(false);
  const [isVisibleInfo, setisVisibleInfo] = useState(false);
  const [isVisibleCompetence, setisVisibleCompetence] = useState(false);
  const [isVisibleFormation, setisVisibleFormation] = useState(false);
  const [isVisibleExp, setisVisibleExp] = useState(false);
  const [isVisibleCompTech, setisVisibleCompTech] = useState(false);

  useEffect(() => {
    if (!isVisibleFormation) {
      let timer1 = setTimeout(() => setisVisibleInfo(true), 100);
      let timer4 = setTimeout(() => setisVisibleExp(true), 150);
      let timer2 = setTimeout(() => setisVisibleCompetence(true), 200);
      let timer5 = setTimeout(() => setisVisibleCompTech(true), 250);
      let timer3 = setTimeout(() => setisVisibleFormation(true), 300);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
        clearTimeout(timer4);
        clearTimeout(timer5);
      };
    }
  });

  return (
    <Container className="info">
      <Row>
        <Col className="first-column" lg="4">
          <div
            className={"my-card appear " + (isVisibleInfo ? "" : "d-none")}
            onMouseEnter={() => setisHoverProfil(true)}
            onMouseLeave={() => setisHoverProfil(false)}
          >
            <div class="card-title">
              <img src={ProfilIcon} alt="" />
              Profil
            </div>
            <div class="card-content">
              <div class="line">
                <span class="bold">Name :</span> Fedor GNETKOV
              </div>
              <div class="line">
                <span class="bold">Age :</span> 35 ans
              </div>
              <div class="line">
                <span class="bold">Adresse :</span>{" "}
                <span className={isHoverProfil ? "hue-color" : ""}>Paris</span>,
                75013
              </div>
              <div class="line">
                <span class="bold">Email :</span>{" "}
                <span className={isHoverProfil ? "hue-color" : ""}>
                  gnetkov@gmail.com
                </span>
              </div>
              <div class="line">
                <span class="bold">Gitlab :</span>{" "}
                <ExternalLink url="https://gitlab.com/users/FedorGN/projects">
                  https://gitlab.com/users/FedorGN/projects
                </ExternalLink>
              </div>
              <div class="line">
                <span class="bold">LinkedIn :</span>{" "}
                <ExternalLink url="https://www.linkedin.com/in/gnetkov/">
                  https://www.linkedin.com/in/gnetkov/
                </ExternalLink>
              </div>
              <div class="line">
                <span class="bold">
                  <span className={isHoverProfil ? "hue-color" : ""}>
                    Recommandations :
                  </span>
                </span>{" "}
                <ExternalLink url="https://www.linkedin.com/in/gnetkov/details/recommendations/">
                  https://www.linkedin.com/in/gnetkov/details/recommendations/
                </ExternalLink>
              </div>
              <div class="line">
                <span class="bold">Medium :</span>{" "}
                <ExternalLink url="https://medium.com/@gnetkov">
                  https://medium.com/@gnetkov
                </ExternalLink>
              </div>
              <div class="line">
                <span class="bold">Téléphone :</span> +33676790363
              </div>
              <div class="line">
                <span class="bold">Nationalité :</span> France, Russie
              </div>
              <div class="line">
                <span class="bold">Langues :</span> Anglais (TOEIC : 925/990),
                français, russe
              </div>
            </div>
          </div>

          <div
            className={
              "my-card appear " + (isVisibleCompetence ? "" : "d-none")
            }
          >
            <div class="card-title">
              <img src={MicIcon} alt="" />
              Compétences
            </div>
            <div class="card-content">
              <ul>
                <li>Analyse de besoins des clients</li>
                <li>Conception des systèmes complexes</li>
                <li>Analyse et traitement de données</li>
                <li>Gestion de projet</li>
                <li>Compétences de communication et de présentation</li>
              </ul>
            </div>
          </div>

          <div
            className={"my-card appear " + (isVisibleFormation ? "" : "d-none")}
            onMouseEnter={() => setisHoverFormation(true)}
            onMouseLeave={() => setisHoverFormation(false)}
          >
            <div class="card-title">
              <img src={BankingIcon} alt="" />
              Formation
            </div>
            <div class="card-content">
              <ul>
                <li>
                  <span class="bold">2010 - 2013</span>
                  <br />
                  <span
                    className={"bold " + (isHoverFormation ? "hue-color" : "")}
                  >
                    Ecole Nationale Supérieure de Techniques Avancées ParisTech
                  </span>
                  , Paris, France <br />
                  Spécialisation : Ingénierie des systèmes <br />
                  Diplôme : Ingénieur (Bac+5)
                </li>
                <li>
                  <span class="bold">2006 - 2012</span>
                  <br />
                  <span
                    className={"bold " + (isHoverFormation ? "hue-color" : "")}
                  >
                    Université Polytechnique de Tomsk
                  </span>
                  , Tomsk, Russie
                  <br />
                  Spécialité : Technologies nucléaires
                  <br />
                  Diplôme : Ingénieur (Bac+5)
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col className="second-colum" lg="8">
          <div
            className={"my-card appear " + (isVisibleExp ? "" : "d-none")}
            onMouseEnter={() => setisHoverExpirience(true)}
            onMouseLeave={() => setisHoverExpirience(false)}
          >
            <div class="card-title">
              <img src={JobIcon} alt="" />
              Expérience
            </div>
            <div class="card-content">
              <Row>
                <Col md="4">
                  <span class="medium">
                    avril 2024 - juillet 2024
                    <br />
                    (3 mois)
                  </span>
                </Col>
                <Col md="8">
                  <p class="f16">
                    <span className={"bold "}>Ondorse</span>, une startup
                    simplifiant le suivi des exigences réglementaires pour les
                    institutions.
                  </p>
                  <span
                    className={"bold " + (isHoverExpirience ? "hue-color" : "")}
                  >
                    Développeur Full Stack
                  </span>
                  <br />
                  Développement Full Stack de l'application web d'Ondorse.
                  Python, TypeScript, tests unitaires, E2E.
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <span class="medium">
                    janvier 2023 - mai 2024
                    <br />
                    (1 an 3 mois)
                  </span>
                </Col>
                <Col md="8">
                  <p class="f16">
                    <span className={"bold "}>Outscale</span>, une entreprise
                    française spécialisée dans le cloud computing.
                  </p>
                  <span
                    className={"bold " + (isHoverExpirience ? "hue-color" : "")}
                  >
                    Développeur Full Stack
                  </span>
                  <br />
                  Développement Full Stack de la marketplace Outscale. Python,
                  TypeScript, CI/CD, tests unitaires, E2E, tests de performance.
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <span class="medium">
                    mars 2022 - juin 2022 <br />
                    (4 mois)
                  </span>
                </Col>
                <Col md="8">
                  <p class="f16">
                    <span className={"bold "}>BForCure</span>, une start-up
                    française, qui développe une plateforme d’analyse PCR.
                  </p>
                  <span
                    className={"bold " + (isHoverExpirience ? "hue-color" : "")}
                  >
                    Développeur BackEnd
                  </span>
                  <br />
                  Développement et mise à jour d'APIs, réduction de coût
                  d'utilisation de la base de données BigQuery (GCP, SQL), tests
                  unitaires.
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <span class="medium">
                    juin 2020 - décembre 2021 <br />
                    (1,5 ans)
                  </span>
                </Col>
                <Col md="8">
                  <p class="f16">
                    <span className={"bold "}>Qualibat</span>, organisme de
                    certification des entreprises du bâtiment.
                  </p>
                  <span
                    className={"bold " + (isHoverExpirience ? "hue-color" : "")}
                  >
                    Team lead (équipe de 5 personnes), Développeur BackEnd
                  </span>
                  <br />
                  Conception et développement d'application web Qualis2. Gestion
                  de projet, conception de la plateforme de certification,
                  développement, analyse de besoins.
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <span class="medium">
                    à partir de mai 2018
                    <br />
                    (5 ans)
                  </span>
                </Col>
                <Col md="8">
                  <p class="f16">
                    <span class="bold">Astudium</span>, une plateforme web qui
                    relie des espaces uniques et des clients.
                  </p>
                  <span
                    className={"bold " + (isHoverExpirience ? "hue-color" : "")}
                  >
                    Fondateur, Développeur Full Stack
                  </span>{" "}
                  <br />
                  Conception et développement d'application web Astudium.
                  Python, TypeScript, négociations, développement commercial.
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <span class="medium">
                    août 2014 - mars 2018 <br />
                    (3 ans 8 mois)
                  </span>
                </Col>
                <Col md="8">
                  <p class="f16">
                    <span class="bold">Oakridge</span>, société de conseil{" "}
                  </p>
                  <span
                    className={"bold " + (isHoverExpirience ? "hue-color" : "")}
                  >
                    Consultant, Team Lead
                  </span>{" "}
                  <br /> Conception, design, développement, tests de simulateurs
                  pleine échelle de centrales nucléaires pour EDF Développement
                  de logiciel, analyse des données, réalisation de simulations
                  pour l’IRSN
                </Col>
              </Row>
            </div>
          </div>
          <div
            className={"my-card appear " + (isVisibleCompTech ? "" : "d-none")}
            onMouseEnter={() => setisHoverCompetence(true)}
            onMouseLeave={() => setisHoverCompetence(false)}
          >
            <div class="card-title">
              <img src={SettingIcon} alt="" />
              Compétences techniques
            </div>
            <div class="card-content">
              <ul>
                <li>Linux, Windows</li>
                <li>
                  Langages de programmation :{" "}
                  <span
                    className={"bold " + (isHoverCompetence ? "hue-color" : "")}
                  >
                    Python
                  </span>{" "}
                  (top 0,3% dans "Clash of code" sur Codingame.com), JavaScript,
                  TypeScript, Go, C/C++, SQL, CSS, OOoBasic
                </li>
                <li>
                  Frameworks{" "}
                  <span
                    className={"bold " + (isHoverCompetence ? "hue-color" : "")}
                  >
                    FastAPI, Flask, Django, ReactJS
                  </span>{" "}
                  [
                  <ExternalLink url="https://medium.com/@gnetkov/modern-application-template-with-fastapi-reactjs-docker-2a87e44dc3b0">
                    Modern Application Template with FastAPI, ReactJS, Docker
                  </ExternalLink>
                  ], Svelte [
                  <ExternalLink url="https://gitlab.com/FedorGN/sveltekit-test">
                    Gitlab : Sveltekit project
                  </ExternalLink>
                  ], Angular [
                  <ExternalLink url="https://gitlab.com/FedorGN/angular-test">
                    Gitlab : Angular project
                  </ExternalLink>
                  ], VueJS [
                  <ExternalLink url="https://gitlab.com/FedorGN/vuejs-app">
                    Gitlab : VueJS project
                  </ExternalLink>
                  ], Gin [
                  <ExternalLink url="https://gitlab.com/FedorGN/go-gin-test">
                    Gitlab : Gin project
                  </ExternalLink>
                  ]
                </li>
                <li>
                  Python packages Pydantic, SQLAlchemy, Alembic, Pytest [
                  <ExternalLink url="https://medium.com/@gnetkov/testing-fastapi-application-with-pytest-57080960fd62">
                    Testing FastAPI Application with Pytest
                  </ExternalLink>
                  ], Unittest, Celery, Pytorch, Pandas, Numpy, mypy
                </li>
                <li>
                  JavaScript packages Mobx, Redux, React Query, Antd React,
                  React-Bootstrap, i18next, React Router, Styled Components,
                  Jest, Chakra UI, Formik, Tailwind, Pinia
                </li>
                <li>
                  E2E testing with PlayWright [
                  <ExternalLink url="https://medium.com/@gnetkov/e2e-tests-with-playwright-3b011df85791">
                    E2E tests with PlayWright
                  </ExternalLink>
                  ]
                </li>
                <li class="mb-2">
                  <span
                    className={"bold " + (isHoverCompetence ? "hue-color" : "")}
                  >
                    Git, Docker
                  </span>
                  , Docker compose, Nginx, Gitlab CI/CD, Amazon S3, BigQuery
                </li>
                <li>
                  MOA, Agile[
                  <ExternalLink url="https://medium.com/@gnetkov/organizing-the-development-of-an-application-from-scratch-685d37b94af3">
                    Organizing the development of an application from scratch
                  </ExternalLink>
                  ,
                  <ExternalLink url="https://medium.com/@gnetkov/documentation-of-complex-business-logic-in-an-application-9a38bbe9f615">
                    Documentation of complex business logic in an application
                  </ExternalLink>
                  ]
                </li>
                <li>
                  Machine learning [
                  <ExternalLink url="https://gitlab.com/FedorGN/machine-learning-exercises">
                    GitLab: machine-learning-exercises
                  </ExternalLink>
                  ]
                </li>
                <li>Conception d'architecture logicielle</li>
                <li>Full stack web développement, REST API</li>
                <li>Analyse fonctionnelle des besoins utilisateurs</li>
                <li>Traduction technique des besoins fonctionnels</li>
                <li>Structuration des bases de données</li>
                <li>Extraction, uniformisation et structuration des données</li>
                <li>
                  Payements sécurisés sur internet (MangoPay, KYC, cash flow)
                </li>
                <li>Systèmes de contrôle-commande</li>
                <li class="mb-2">
                  Rédaction des spécifications techniques, des cahiers de
                  charges
                </li>
                <li>
                  Outils :{" "}
                  <span
                    className={"bold " + (isHoverCompetence ? "hue-color" : "")}
                  >
                    Figma, Confluence, Jira, Miro
                  </span>
                </li>
                <li>
                  Outils graphiques : Adobe Photoshop, Adobe Premier, CorelDraw,
                  Microsoft Visio
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
