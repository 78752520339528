import { Container, Row, Col } from "react-bootstrap";
import SendIcon from "./assets/icons/icon_ic_send.svg";
import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import emailjs from "emailjs-com";
import { EMAILJS } from "./Email";

export default function Contact() {
  const [isHoverContact, setisHoverContact] = useState(false);
  const [isSendingMail, setisSendingMail] = useState(false);
  const [isShowValidationError, setisShowValidationError] = useState(false);
  const [isModalSuccesshow, setisModalSuccesshow] = useState(false);
  const [isModalErrorshow, setisModalErrorshow] = useState(false);
  const [EmailForm, setEmailForm] = useState("");
  const [NameForm, setNameForm] = useState("");
  const [SubjectForm, setSubjectForm] = useState("");
  const [MessageForm, setMessageForm] = useState("");
  const [isVisibleContact, setisVisibleContact] = useState(false);

  useEffect(() => {
    if (!isVisibleContact) {
      let timer1 = setTimeout(() => setisVisibleContact(true), 1400);

      return () => {
        clearTimeout(timer1);
        console.log("cou");
      };
    }
  });

  const handleCloseSuccesModal = () => setisModalSuccesshow(false);
  const handleShowSuccesModal = () => setisModalSuccesshow(true);

  const handleCloseErrorModal = () => setisModalErrorshow(false);
  const handleShowErrorModal = () => setisModalErrorshow(true);

  emailjs.init("EMAILJS.USER_ID");

  const handleClick = (e) => {
    e.preventDefault();

    if (
      !EmailForm ||
      !NameForm ||
      !SubjectForm ||
      !MessageForm ||
      !String(EmailForm)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    )
      setisShowValidationError(true);
    else {
      setisShowValidationError(false);
      setisSendingMail(true);
      console.log(isSendingMail);

      // data to send
      // keys must be the same as in template in EmailJS
      var data = {
        from_name: NameForm,
        reply_to: EmailForm,
        from_subject: SubjectForm,
        message: MessageForm,
      };

      emailjs
        .send(EMAILJS.SERVICE_ID, EMAILJS.TEMPLATE_ID, data, EMAILJS.USER_ID)
        .then(
          (result) => {
            setNameForm("");
            setMessageForm("");
            setEmailForm("");
            setSubjectForm("");
            handleShowSuccesModal();
            setisSendingMail(false);
            // alert("SUCCESS!", result.status, result.text);
          },
          (error) => {
            // alert("ERR!", error);
            console.log(isSendingMail);
            handleShowErrorModal();
            setisSendingMail(false);
          }
        );
    }
  };

  return (
    <>
      <Container className="contact">
        <Row>
          <Col sm={12} md={12}>
            <div
              className={
                "my-card card-contact appear " +
                (isVisibleContact ? "" : "d-none")
              }
              onMouseEnter={() => setisHoverContact(true)}
              onMouseLeave={() => setisHoverContact(false)}
            >
              <div class="card-title">
                <img src={SendIcon} alt="" />
                Contactez-moi
              </div>
              <div class="card-content">
                <Form sm={12} md={12}>
                  <Col sm={12} md={8} lg={5} xl={4}>
                    <Form.Group className="mb-3" controlId="user_name">
                      <Form.Label className="bold form-label">
                        Votre prénom, nom*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="user_name"
                        placeholder="Claude Monet"
                        onChange={(event) => setNameForm(event.target.value)}
                        value={NameForm}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="reply_to">
                      <Form.Label className="bold form-label">
                        Votre email*
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="claude@monet.com"
                        name="reply_to"
                        onChange={(event) => setEmailForm(event.target.value)}
                        value={EmailForm}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="from_subject">
                      <Form.Label className="bold form-label">
                        Thème*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Proposition de ..."
                        name="from_subject"
                        onChange={(event) => setSubjectForm(event.target.value)}
                        value={SubjectForm}
                      />
                    </Form.Group>
                  </Col>
                  <Form.Group className="mb-3" controlId="message">
                    <Form.Label className="bold">Votre message*</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      name="message"
                      placeholder="Bonjour Fedor ..."
                      onChange={(event) => setMessageForm(event.target.value)}
                    />
                  </Form.Group>
                </Form>
                <div>
                  <p className={isShowValidationError ? "" : "d-none"}>
                    Tous les champs sont obligatoires
                  </p>
                  <Button
                    className={"btn-primary mt-0"}
                    onClick={(e) => handleClick(e)}
                    disabled={isSendingMail}
                  >
                    <span className={isHoverContact ? "hue-color" : ""}>
                      {isSendingMail ? "Envoi..." : "Envoyer"}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        show={isModalSuccesshow}
        onHide={handleCloseSuccesModal}
        keyboard={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
      >
        <div className="modal-toast-body"> Votre message a été envoyé !</div>
      </Modal>
      <Modal
        show={isModalErrorshow}
        onHide={handleCloseErrorModal}
        keyboard={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
      >
        <div className="modal-toast-body">Une erreur est survenue :(</div>
      </Modal>
    </>
  );
}
