import { Modal, Button, Carousel } from "react-bootstrap";
import React, { useState } from "react";
import photo1 from "../assets/images/climbingapp1.png";
import photo2 from "../assets/images/climbingapp2.png";
import photo3 from "../assets/images/climbingapp3.png";
import photo4 from "../assets/images/climbingapp4.png";

export default function ModalSlideShowClimbApp(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="btn-primary mt-3" onClick={handleShow}>
        <span className={props.isHoverAutresProjets ? "hue-color" : ""}>
          Voir captures d’écran
        </span>
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
      >
        <Carousel variant="dark">
          <Carousel.Item>
            <img className="d-block w-100" src={photo1} alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={photo2} alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={photo3} alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={photo4} alt="" />
          </Carousel.Item>
        </Carousel>
      </Modal>
    </>
  );
}
