import { Container, Row, Col } from "react-bootstrap";
import StarIcon from "./assets/icons/icon_ic_star.svg";
import CheckIcon from "./assets/icons/icon_ic_check.svg";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ModalSlideShowClimbApp from "./components/ModalSlideShowClimbApp";
import ModalSlideShowJustTuneGuitare from "./components/ModalSlideShowJustTuneGuitar";
import ModalSlideAiEmailResponder from "./components/ModalSlideAiEmailResponder";
import ExternalLink from "./components/ExternalLink";

export default function AutresProjets() {
  const [isHoverAutresProjets, setisHoverAutresProjets] = useState(false);
  const [isVisibleProjets, setisVisibleCompTech] = useState(false);

  useEffect(() => {
    if (!isVisibleProjets) {
      let timer1 = setTimeout(() => setisVisibleCompTech(true), 1200);

      return () => {
        clearTimeout(timer1);
      };
    }
  });

  return (
    <Container className="projets">
      <Row>
        <Col>
          <div
            className={
              "my-card card-projet appear " + (isVisibleProjets ? "" : "d-none")
            }
            onMouseEnter={() => setisHoverAutresProjets(true)}
            onMouseLeave={() => setisHoverAutresProjets(false)}
          >
            <div class="card-title">
              <img src={CheckIcon} alt="" />
              Autres projets
            </div>
            <div class="card-content">
              {/* Reply master app */}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">2024</span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>AI email responder</span>, Paris,
                    France – Une application web offrant une expérience
                    utilisateur fluide, qui génère des réponses aux emails.
                    L'application permet de personnaliser le ton des réponses
                    (formel, amical, etc.) et de proposer plusieurs variantes de
                    texte pour chaque réponse, facilitant ainsi une
                    communication adaptée.
                  </p>
                  <span className={"bold "}>Développeur Full Stack</span>
                  <ul>
                    <li>
                      Conception et développement d'application web en utilisant {" "}
                      <span
                      className={
                        "medium " + (isHoverAutresProjets ? "hue-color" : "")
                      }
                    >API d'OpenAI</span>.
                    </li>
                    <li>
                      Python, FastAPI, ReactJS, TypeScript, API OpenAI, Antd.
                    </li>
                  </ul>
                  <div className="d-flex gap-2">
                    <ModalSlideAiEmailResponder
                      isHoverAutresProjets={isHoverAutresProjets}
                    />
                    <Button
                      className="btn-primary mt-3 pl-3"
                      href="https://ai-email-lemon.vercel.app/"
                      target="_blank"
                    >
                      <span className={isHoverAutresProjets ? "hue-color" : ""}>
                        Visiter le site
                      </span>
                    </Button>
                  </div>
                </Col>
              </Row>
              {/* Just tune Guitar app */}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">2024</span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>Just tune guitar</span>, Paris,
                    France – une{" "}
                    <span
                      className={
                        "medium " + (isHoverAutresProjets ? "hue-color" : "")
                      }
                    >
                      application android
                    </span>{" "}
                    pour faire l'accordage standard de la guitare.
                  </p>
                  <span className={"bold "}>Développeur mobile</span>
                  <ul>
                    <li>
                      Conception et développement d'application native Android.
                      Conception de maquettes Figma, développement mobile
                      d'application.
                    </li>
                    <li>Kotlin, TarsosDSP, Android Studio.</li>
                  </ul>
                  <ModalSlideShowJustTuneGuitare
                    isHoverAutresProjets={isHoverAutresProjets}
                  />
                </Col>
              </Row>
              {/* Maching learning info*/}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">2024</span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>
                      Projets{" "}
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        "Machine learning"
                      </span>
                    </span>
                    , Paris, France – exercices d'apprentissage automatique.
                  </p>
                  <span className={"bold "}>Développeur machine learning</span>
                  <ul>
                    <li>
                      J'ai complété plusieurs exercices d'apprentissage
                      automatique, où j'ai pratiqué divers concepts tels que la
                      prétraitement des données, l'entraînement des modèles et
                      l'évaluation.
                    </li>
                    <li>
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        Python
                      </span>
                      , Pytorch, Numpy, Pandas, Scikit-learn, Gitlab.
                    </li>
                    <li>
                      Linear regression:{" "}
                      <ExternalLink url="https://gitlab.com/FedorGN/machine-learning-exercises/-/tree/main/linear_regression">
                        Voir sur Gitlab
                      </ExternalLink>
                    </li>
                    <li>
                      Logistic Regression Classification:{" "}
                      <ExternalLink url="https://gitlab.com/FedorGN/machine-learning-exercises/-/tree/main/logistic_regression_classification">
                        Voir sur Gitlab
                      </ExternalLink>
                    </li>
                    <li>
                      Convolutional Neural Network:{" "}
                      <ExternalLink url="https://gitlab.com/FedorGN/machine-learning-exercises/-/tree/main/neural_networks">
                        Voir sur Gitlab
                      </ExternalLink>
                    </li>
                    <li>
                      Natural Language Processing, sentiment analysis using
                      logistic regression:{" "}
                      <ExternalLink url="https://gitlab.com/FedorGN/machine-learning-exercises/-/tree/main/nlp_sentiment_analysis">
                        Voir sur Gitlab
                      </ExternalLink>
                    </li>
                    <li>
                      Segmentation using K-Means clustering:{" "}
                      <ExternalLink url="https://gitlab.com/FedorGN/machine-learning-exercises/-/tree/main/unsupervised_learning_kmeans_clustering">
                        Voir sur Gitlab
                      </ExternalLink>
                    </li>
                  </ul>
                  <Button
                    className="btn-primary mt-3"
                    href="https://gitlab.com/FedorGN/machine-learning-exercises"
                    target="_blank"
                  >
                    <span className={isHoverAutresProjets ? "hue-color" : ""}>
                      Visiter le repository GitLab
                    </span>
                  </Button>
                </Col>
              </Row>

              {/* ToDo info*/}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">2022 - 2024</span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>ToDo</span>, Paris, France – Une
                    petite application{" "}
                    <span
                      className={
                        "medium " + (isHoverAutresProjets ? "hue-color" : "")
                      }
                    >
                      open-source
                    </span>{" "}
                    pour créer un modèle pour les autres applications.
                  </p>
                  <span className={"bold "}>FullStack développeur</span>
                  <ul>
                    <li>
                      Développement BackEnd avec le framework python FastAPI.
                      Création de routes: création d'utilisateurs, connexion,
                      mot de passe oublié et autres. Développement FrontEnd avec
                      le framework JavaScript ReactJS et package React
                      Bootstrap. Containerization de composants d'application
                      avec Docker et Docker-compose pour pouvoir cloner le
                      projet et deployer un environment de développement avec
                      une seule commande. Création de maquettes avec auto-layout
                      dans Figma.
                    </li>
                    <li>
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        Python
                      </span>{" "}
                      (Framework{" "}
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        FastAPI
                      </span>
                      ), JavaScript (Framework{" "}
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        ReactJS
                      </span>
                      ),{" "}
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        Docker
                      </span>
                      , Docker-compose, Nginx, Redis, SqlLite, GitLab,
                      SQLAlchemy, MailHog, Figma.
                    </li>
                    <li>
                      Article sur Medium:{" "}
                      <ExternalLink url="https://medium.com/@gnetkov/modern-application-template-with-fastapi-reactjs-docker-2a87e44dc3b0">
                        Modern Application Template with FastAPI, ReactJS,
                        Docker
                      </ExternalLink>
                    </li>
                    <li>
                      Maquettes figma:{" "}
                      <ExternalLink url="https://www.figma.com/file/dDITdZCtk8jSuA27AkuwPj/Awesome-Todo">
                        https://www.figma.com/file/dDITdZCtk8jSuA27AkuwPj/Awesome-Todo
                      </ExternalLink>
                    </li>
                  </ul>
                  <Button
                    className="btn-primary mt-3"
                    href="https://gitlab.com/FedorGN/todo-fastapi-reactjs"
                    target="_blank"
                  >
                    <span className={isHoverAutresProjets ? "hue-color" : ""}>
                      Visiter le repository GitLab
                    </span>
                  </Button>
                </Col>
              </Row>
              {/* ClimbingApp info*/}
              <Row className="row-projet">
                <Col sm="3" md="3" lg="2" className="pb-2">
                  <span class="medium ">2022</span>
                </Col>
                <Col sm="9" md="9" lg="10">
                  <p class="f16">
                    <img src={StarIcon} className="sm-icon" alt="" />
                    <span className={"bold "}>ClimbingApp Roc14</span>, Paris,
                    France – ClimbingApp est une application web pour le club
                    d'escalade Roc14. Un reseau social pour suivre votre progrès
                    et le progrès de vos amis.
                  </p>
                  <span className={"bold "}>FullStack développeur</span>
                  <ul>
                    <li>
                      Conception et développement d'application web ClimbingApp.
                      Conception de maquettes{" "}
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        Figma
                      </span>
                      , développement BackEnd et FrontEnd d'application.
                    </li>
                    <li>
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        Python
                      </span>{" "}
                      (Framework{" "}
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        FastAPI
                      </span>
                      ), JavaScript (Framework{" "}
                      <span
                        className={
                          "medium " + (isHoverAutresProjets ? "hue-color" : "")
                        }
                      >
                        ReactJS
                      </span>
                      ), GitLab, Docker, Docker-compose, Nginx, PostgreSQL,
                      PgAdmin, Redis, Bootstrap, MailHog
                    </li>
                  </ul>
                  <ModalSlideShowClimbApp
                    isHoverAutresProjets={isHoverAutresProjets}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
