import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

import "./assets/css/styles.css";

import Header from "./Header";
import Info from "./Info";
import Projets from "./Projets";
import Contact from "./Contact";
import AutresProjets from "./AutresProjets";

function App() {
  document.title = "Fedor GNETKOV - CV";
  return (
    <div>
      {/* Coming soon */}
      <Header />
      <Info />
      <Projets />
      <AutresProjets />
      <Contact />
    </div>
  );
}

export default App;
