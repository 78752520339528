import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

// Define the styled component
const StyledLink = styled.a`
  color: black;
  text-decoration: underline;
  margin-right: 5px;
  margin-left: 5px;

  &:hover {
    color: #0d6efd;
  }
`;

const ExternalLink = ({ url, children }) => {
  return (
    <StyledLink href={url} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon
        icon={faExternalLinkAlt}
        style={{ marginRight: "5px" }}
      />
      {children}
    </StyledLink>
  );
};

export default ExternalLink;
