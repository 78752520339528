import { Container, Row, Col } from "react-bootstrap";
import photo from "./assets/images/photo.jpg";
import React from "react";

export default function Header() {
  return (
    <>
      <Container fluid>
        <Row className="header-color">
          <Col></Col>
        </Row>
      </Container>
      <Container>
        <Row className="header-second-row">
          <Col lg="2" md="4" sm="12" className="image">
            <img src={photo} alt=""></img>
          </Col>
          <Col lg="4" md="8" sm="12" className="name">
            <Row>
              <Col>
                <h1>Fedor GNETKOV</h1>
                <h3>Team lead, Développeur Python/JS</h3>
              </Col>
            </Row>
          </Col>
          <Col lg="5" md="12" sm="12" className="availible">
            <h2>Disponible immédiatement</h2>
          </Col>
          <Col lg="1" md="0" sm="0">
            <div></div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
